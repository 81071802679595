import React from 'react';
import { graphql } from 'gatsby';
import Seo from '../components/seo';
import NavTags from '../components/NavTags';
import ProjetCard from '../components/ProjetCard';
import clsx from 'clsx';
// import { TagsWrapper } from '../contexts/TagsWrapper';

export const query = graphql`
  query Tag($slug: String!) {
    sanityProjets {
      id
      seo {
        ...seo
      }
      slug {
        current
      }
      title {
        ...localeString
      }
      navTags {
        tagGroup {
          title {
            ...localeString
          }
          tags {
            _type
            slug {
              current
            }
            title {
              ...localeString
            }
          }
        }
      }
    }
    allSanityProjet(filter: { tags: { elemMatch: { slug: { current: { eq: $slug } } } } }) {
      nodes {
        ...projetCard
      }
    }
  }
`;

const Tag = ({ pageContext, data }) => {
  const { seo, navTags } = data.sanityProjets;
  const projectsGrid = data.allSanityProjet.nodes;
  // console.log(pageContext);
  return (
    <div className="main-area pt-_xl md:pt-xxl">
      {seo && (
        <Seo
          metaTitle={seo.metaTitle}
          metaDescription={seo.metaDescription}
          metaImage={seo.metaImage?.asset.url}
          template={`template-tag tag-${pageContext.slug}`}
          page={true}
        />
      )}

      <div className="container">
        {/* <div className="pb-_md md:pb-md sticky top-header-mid z-10">
          <div className="absolute inset-0 background-gradient bg-4-"></div>
          <NavTags input={navTags} />
        </div> */}
        <div className="pb-_md md:pb-md sticky z-10 top-0 top-header-mid- pt-header-mid ">
          <div className="absolute inset-0 background-gradient bg-4-"></div>
          <NavTags input={navTags} />
        </div>
        <div className="projets-grid">
          {/* <pre>{JSON.stringify(projectsGrid)}</pre> */}
          <div className="row items-end">
            {projectsGrid.map((item, i) => (
              <div className={clsx('col-md-4 col-xs-12')} key={i}>
                <ProjetCard input={item} />
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Tag;
